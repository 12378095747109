import React from 'react';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeAlert } from '../../../actions/alerts';
import styles from '../style.scss';

export const AlertBadgeComponent = class AlertBadge extends React.Component {
  componentDidMount() {
    if (this.props.timeout !== -1) {
      this.timeout = setTimeout(
        () => {
          this.props.actions.closeAlert(this.props.id);
        },
        this.props.timeout,
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    return (
      <Alert
        color={this.props.color}
        onClick={() => { this.props.actions.closeAlert(this.props.id); }}
        className={styles.alert_item}
      >{this.props.children}
        {!this.props.hideClose ? (
          <i
            className={`material-icons ${styles.alert_box_close}`}
            onClick={() => { this.props.actions.closeAlert(this.props.id); }}
          >
            close
          </i>
        ) : null}
      </Alert>
    );
  }
};

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ closeAlert }, dispatch),
});

export default connect(null, mapDispatch)(AlertBadgeComponent);
