import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';
import Alert from './AlertBadge';
import styles from './style.scss';

export const AlertsComponent = function (props) {
  const { alerts, intl } = props;

  if (!alerts) {
    return false;
  }

  return (
    <div>
      <div className={styles.alerts_container}>
        {alerts ? alerts.map(alert => (
          <Alert
            key={alert.id}
            id={alert.id}
            color={alert.color}
            timeout={alert.timeout}
            className={styles.alert_box}
          >{typeof (alert.text) === 'string' ? alert.text : intl.formatMessage(alert.text[0])}
          </Alert>
        ))
          :
          ''
        }
      </div>
    </div>
  );
};

const mapState = state => ({
  alerts: state.alerts && state.alerts.alerts,
});

export default injectIntl(connect(mapState)(withStyles(styles)(AlertsComponent)));
