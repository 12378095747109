/* eslint-disable quote-props */
/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { setLocale } from '../../actions/intl';
import { EN_US, EN_GB, FR_CA } from '../../helpers/localeHelper';
import styles from './styles.scss';

const messages = defineMessages({
  language: {
    id: 'languageSwitcher.language',
    defaultMessage: 'Language',
    description: 'Language',
  },
});
/* config for app translations full doc https://github.com/yahoo/react-intl */
const localeDict = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  [EN_US]: 'English (US)',
  [EN_GB]: 'English (UK)',
  [FR_CA]: 'Français',
  /* @intl-code-template-end */
};

class LanguageSwitcher extends React.Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.setLocale = this.setLocale.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  setLocale = (locale) => {
    this.props.actions.setLocale({ locale });
  };

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { currentLocale, availableLocales, className } = this.props;
    const isSelected = locale => locale === currentLocale;
    const localeName = locale => localeDict[locale] || locale;
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        direction="down"
        className={`${className} ${styles.languageDropdown}`}
      >
        <DropdownToggle caret color="info" className={styles.languageButton} >
          <FormattedMessage {...messages.language} />
          <div className={styles.carrot}>
            <i
              className={`fas fa-fw fa-caret-${this.state.dropdownOpen ? 'up' : 'down'}`}
            />
          </div>
        </DropdownToggle>
        <DropdownMenu className={styles.dropDown}>
          <div className={`${styles.dropdownWrapper} dropdown-wrapper`}>
            {availableLocales.map(locale => (
              <DropdownItem
                className={styles.languageItem}
                key={locale}
                disabled={isSelected(locale)}
                onClick={async (e) => {
                  e.preventDefault();
                  await this.setLocale(locale);
                }}
              >
                <span>
                  {localeName(locale)}
                </span>
                {isSelected(locale) && <i className={`fas fa-check ${styles.ActiveIcon}`} />}
              </DropdownItem>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ setLocale }, dispatch),
});

export default injectIntl(connect(mapState, mapDispatch)(withStyles(styles)(LanguageSwitcher)));
