import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { FormattedHTMLMessage, defineMessages } from 'react-intl';
import { setActive } from '../../actions/activity';
import styles from './styles.scss';

const messages = defineMessages({
  modalIdleTitle: {
    id: 'modal.idleTitle',
    defaultMessage: 'Your session is about to timeout',
    description: 'Idle modal title',
  },
  buttonContinue: {
    id: 'button.continue',
    defaultMessage: 'Continue',
    description: 'Continue Button',
  },
  messageTimeoutPrelude: {
    id: 'message.timoutPrelude',
    defaultMessage: 'Your session will expire automatically in ',
    description: 'Timeout prelude',
  },
  messageTimeoutEnding: {
    id: 'message.timoutEnding',
    defaultMessage: ` seconds.
                      Select "Continue" to extend your session`,
    description: 'Timeout ending',
  },
});

class Idle extends React.Component {
  constructor(props) {
    super(props);
    this.cancelIdle = this.cancelIdle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.updateTimeRemaining = this.updateTimeRemaining.bind(this);
    this.state = {
      modalOpen: true,
      toggle: true,
      timeRemaining: 60 * 2,
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.updateTimeRemaining, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.setState({
      modalOpen: false,
      toggle: false,
      timeRemaining: 60 * 2,
    });
  }

  cancelIdle() {
    this.setState({
      modalOpen: false,
    });
    this.props.actions.setActive();
    this.toggleModal();
  }

  toggleModal() {
    this.setState({
      toggle: !this.state.toggle,
    });
  }

  updateTimeRemaining() {
    this.setState({
      timeRemaining: this.state.timeRemaining - 1,
    });
    if (this.state.timeRemaining < 1) {
      // logout out user
      window.location = '/logout';
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.modalOpen}
        toggle={this.state.toggleModal}
      >
        <ModalHeader>
          <div className="modal-title h2">
            <FormattedHTMLMessage {...messages.modalIdleTitle} />
          </div>
          <Button className={styles.closeButton} onClick={this.cancelIdle}>
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Label htmlFor="inputState">
            <h5>
              <FormattedHTMLMessage {...messages.messageTimeoutPrelude} />
              {' '}
              {this.state.timeRemaining}
              {' '}
              <FormattedHTMLMessage {...messages.messageTimeoutEnding} />
            </h5>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.cancelIdle}
          >
            <FormattedHTMLMessage {...messages.buttonContinue} />
          </Button>
        </ModalFooter>
      </Modal>

    );
  }
}

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ setActive }, dispatch),
});


export default connect(null, mapDispatch)(Idle);
