exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face{font-family:font18c563f35f0985f91f2044a43a6904f3;src:url(\"/assets/fonts/MuseoSans-900.otf?#iefix\") format(\"embedded-opentype\"),url(\"/assets/fonts/18c563f35f0985f91f2044a43a6904f3.woff\") format(\"woff\")}@font-face{font-family:MuseoSans-900;src:local(\"MuseoSans-900\"),url(\"/assets/fonts/18c563f35f0985f91f2044a43a6904f3.woff\") format(\"woff\")}.NYekn{text-align:center;font-family:Roboto,sans-serif}._1Ey1i{-webkit-animation:_2xD4l infinite 20s linear;animation:_2xD4l infinite 20s linear;height:80px}._1DhOU{background-color:#222;height:150px;padding:20px;color:#fff}.Ms_E7{font-size:1.5em}._14quy{font-size:large}._1PLJx{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:100%}._3TVHp{-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:0;flex-shrink:0;-ms-flex-preferred-size:auto;flex-basis:auto}._1oh0h{max-height:345px}._1oh0h,._3dCRz,._18ReO{-ms-flex-positive:0;flex-grow:0;-ms-flex-negative:0;flex-shrink:0;-ms-flex-preferred-size:auto;flex-basis:auto}", ""]);

// exports
exports.locals = {
	"App": "NYekn",
	"App-logo": "_1Ey1i",
	"App-logo-spin": "_2xD4l",
	"App-header": "_1DhOU",
	"App-title": "Ms_E7",
	"App-intro": "_14quy",
	"App-container": "_1PLJx",
	"content": "_3TVHp",
	"footer": "_1oh0h",
	"banner": "_18ReO",
	"idle": "_3dCRz"
};