import {
  SET_IDLE,
  SET_ACTIVE,
} from '../constants';

export function setIdle() {
  return {
    type: SET_IDLE,
  };
}

export function setActive() {
  return {
    type: SET_ACTIVE,
  };
}
