import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import Idle from './Idle';
import style from './styles.scss';

const IdlePopup = function (props) {
  const { active, user } = props;

  if (active) {
    return false;
  }

  return (
    <div>
      {(user && !active) ?
        <Idle />
        :
        ''
      }
    </div>
  );
};

const mapState = state => ({
  active: state.activity && state.activity.active,
  user: state.user,
});

export default connect(mapState)(withStyles(style)(IdlePopup));
