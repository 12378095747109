/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// external-global styles must be imported in JS.
import 'normalize.css';
import { compose, withApollo } from 'react-apollo';
import appCss from '../App.scss';
import Idle from '../Idle';
import Alerts from '../Alerts';
import Header from '../Header';
import { alert } from '../../actions/alerts';
import { getUser } from '../../selectors';
import { setIdle } from '../../actions/activity';
import Footer from '../Footer';
import PolicyBanner, { cookiesPolicyKey } from '../PolicyBanner';
import styles from './Layout.scss';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const { cookies } = this.props;
    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.state = {
      errorMessage: cookies.get('errorMessage') || null,
      infoMessage: cookies.get('infoMessage') || null,
      successMessage: cookies.get('successMessage') || null,
    };
  }

  componentWillMount() {
    if (this.state.errorMessage && this.state.errorMessage.length > 0) {
      this.props.actions.alert(this.state.errorMessage, 'danger', 7000);
    }
    if (this.state.infoMessage && this.state.infoMessage.length > 0) {
      this.props.actions.alert(this.state.infoMessage, 'warning', 7000);
    }
    if (this.state.successMessage && this.state.successMessage.length > 0) {
      this.props.actions.alert(this.state.successMessage, 'success', 7000);
    }
  }

  componentDidMount() {
    if (this.props.user && !this.props.user.profiles) {
      window.location = '/logout?system=UPDATE';
    }
  }

  componentWillUnmount() {
    clearInterval(this.refresh);
  }

  onAction() {
    if (this.idleTimer) {
      this.idleTimer.reset();
    }
  }

  onIdle() {
    this.props.actions.setIdle();
  }

  render() {
    let showBanner = false;
    if (typeof window !== 'undefined') {
      showBanner = !window.localStorage.getItem(cookiesPolicyKey);
    }
    return (
      <IdleTimer
        ref={(ref) => { this.idleTimer = ref; }}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={1000 * 60 * 18}
      >
        <div className={appCss['App-container']}>
          <header>
            <Alerts />
            <Header />
          </header>
          {this.props.children}
          <Footer />
          <div className={appCss.banner}>
            {showBanner ?
              <PolicyBanner />
              : ''}
          </div>
          <div className={appCss.idle}>
            <Idle />
          </div>
        </div>
      </IdleTimer>
    );
  }
}

const mapState = state => ({
  user: getUser(state),
});

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ alert, setIdle }, dispatch),
});

export default compose(
  withStyles(styles, appCss),
  connect(mapState, mapDispatch),
)(withApollo(withCookies(Layout)));
