const privacyPolicies = {
  'en-us': '/about-us/privacy-policy',
  'en-gb': '/en-gb/about-us/privacy-policy',
  'es':    '/es/quienes-somos/politica-privacidad',
  'fr-ca': '/fr-ca/a-propos-de-nous/politique-de-confidentialite',
};

const urlHelper = {
  storageMartBaseUrl: process.env.STORAGEMART_BASE_URL,
  storageMartLocaleUrl: (baseUrl, locale) => {
    if (locale.toLowerCase() === 'en-us') {
      return baseUrl;
    } else {
      return `${baseUrl}/${locale.toLowerCase()}`;
    }
  },
  storageMartPrivacyPolicyUrl: (baseUrl, locale) => {
    const privacyPolicyPath = privacyPolicies[locale.toLowerCase()];
    return `${baseUrl}${privacyPolicyPath}`;
  },
};

export default urlHelper;
