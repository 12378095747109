exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1-8aw{width:100%;background-color:#4a4a4a;position:fixed;bottom:0;color:#fff;padding:24px 32px;font-size:16px;font-weight:300;text-align:center}@media (max-width:991px){._1-8aw{padding:16px;text-align:left}}._1-8aw a{margin-left:5px;text-decoration:underline;color:#70cacb}._1lZQK{color:#4a4a4a!important;font-weight:700!important;margin-left:32px}@media (max-width:991px){._1lZQK{display:block!important;margin:16px 0}}", ""]);

// exports
exports.locals = {
	"banner": "_1-8aw",
	"acceptBtn": "_1lZQK"
};