exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Museo Sans\",\"Nunito Sans\",sans-serif;--font-family-base-2:\"Roboto Condensed\";--hyper-link-color:#4a90e2;--font-color:#52585b;--header-color:#333;--primary-color:#ffc72c;--primary-color-1:#fff8e1;--primary-color-2:#ffebb3;--primary-color-3:#ffde83;--primary-color-4:#ffbe13;--primary-color-5:#feaf05;--primary-color-6:#fe9d0e;--primary-color-6:#fd560d;--secondary-color:#64ccc9;--secondary-color-1:#ff585d;--secondary-color-2:#dcf2f3;--secondary-color-3:#a6e0de;--secondary-color-5:#00b7b2;--secondary-color-6:#00a69f;--secondary-color-7:#00968c;--secondary-color-8:#00796e;--secondary-color-9:#00695e;--secondary-color-10:#004d41;--secondary-color-11:#ffecf0;--secondary-color-12:#ffd0d7;--secondary-color-13:#f89fa3;--secondary-color-14:#f2787e;--secondary-color-15:#ff4543;--secondary-color-16:#f73d43;--secondary-color-17:#e5333c;--secondary-color-17:#d82b35;--secondary-color-17:#c91f29;--copy-color-1:#000;--copy-color-2:#4a4a4a;--background-color-1:#fff;--background-color-2:#f5f5f5;--background-color-3:#eaeaea;--background-color-4:#d8d8d8;--background-color-5:#9b9b9b;--background-color-6:#5c5c5c;--background-color-7:#737373}._1s1oA{cursor:pointer;position:absolute;top:10px;background-color:transparent!important;border-color:transparent!important;right:20px;left:auto;margin-left:50px;padding:0;text-decoration:none;display:block;-webkit-box-shadow:none!important;box-shadow:none!important;-webkit-transition:.3s;transition:.3s;font-family:Roboto Condensed;font-family:var(--font-family-base-2);text-transform:lowercase;float:right;font-size:24px;font-size:1.5rem;font-weight:700;line-height:1;color:#4a4a4a;text-shadow:0 1px 0 #fff}._1s1oA i{color:#4a4a4a;font-size:20px}._27RLe{margin-bottom:0;line-height:1.5;font-weight:900;padding-top:10px;font-size:32px;font-size:2rem;font-family:Museo Sans,Nunito Sans,sans-serif;font-family:var(--font-family-base)}", ""]);

// exports
exports.locals = {
	"closeButton": "_1s1oA",
	"title": "_27RLe"
};