import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Container, Row, Col, Label } from 'reactstrap';
import { withApollo, compose } from 'react-apollo';
import { connect } from 'react-redux';
import urlHelper from '../../helpers/urlHelper';
import { getCurrentLocale } from '../../selectors';
import localeHelper from '../../helpers/localeHelper';
import styles from './styles.scss';

const messages = defineMessages({
  footerFindStorage: {
    id: 'footer.FindStorage',
    defaultMessage: 'Find Storage Near Me',
    description: 'Find storage near you text',
  },
  footerFindStoragePlaceholder: {
    id: 'footer.FindStoragePlaceholder',
    defaultMessage: 'Enter zip/postal/city',
    description: 'Find storage near you input placeholder',
  },
  footerNeedHelp: {
    id: 'footer.NeedHelp',
    defaultMessage: 'Need Help?',
    description: 'Need help text',
  },
  footerCopyRight: {
    id: 'footer.copyright',
    defaultMessage: 'StorageMart. All Rights Reserved. STORAGEMART® Manhattan Mini Storage®',
    description: 'Copyright text',
  },
  footerPrivacyPolicy: {
    id: 'footer.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy text',
  },
  footerOwningEntities: {
    id: 'footer.owningEntities',
    defaultMessage: 'Owning Entities',
    description: 'Owning entities text',
  },
  footerPromotionsDisclaimer: {
    id: 'footer.promotionsDisclaimer',
    defaultMessage: `*Promotions are subject to change at any time due to
                    availability and
                    our discretion. Offered only on select units. This offer
                    applies only
                    to the rental fee. Other restrictions may apply including taxes.
                    Promotions may
                    apply to the next calendar month or to the remainder of the
                    current calendar
                    month depending on the day of rental and local conditions. See
                    contract for full details.`,
    description: 'Promotions disclaimer text',
  },
  assistanceStatement: {
    id: 'footer.assistanceStatement',
    defaultMessage: `For assistance navigating our website or to manage your account,
      please call us at 877-786-7243.`,
    description: 'assistance text',
  },
  helpPhone: {
    id: 'header.helpPhone',
    defaultMessage: '(000) 000-0000',
    description: 'Phone in page footer',
  },
});

export function FooterComponent(props) {
  const {
    storageMartBaseUrl,
    currentLocale,
    intl,
  } = props;
  const localeBaseUrl = urlHelper.storageMartLocaleUrl(storageMartBaseUrl, currentLocale);
  const privacyPolicyUrl = urlHelper.storageMartPrivacyPolicyUrl(storageMartBaseUrl, currentLocale);
  const phoneNumber = localeHelper.getPhoneNumber(intl.formatMessage(messages.helpPhone), intl.locale);

  return (
    <footer>
      <Container>
        <Row>
          <Col>
            <div className={styles.divider} />
          </Col>
        </Row>
      </Container>
      <Container className={styles.root}>
        <Row className={styles.row}>
          <Label xs={12}>
            <div className={styles.searchLabel}>
              <FormattedMessage {...messages.footerFindStorage} />
            </div>
          </Label>
          <Col sm={6} xs={12}>
            <div className={styles.postInput}>
              <form action={`${localeBaseUrl}/search`}>
                <span className={styles.postInput_container}>
                  <FormattedMessage {...messages.footerFindStoragePlaceholder}>
                    {message => (
                      <input
                        id="searchtext"
                        type="text"
                        name="query"
                        placeholder={message}
                        className={styles.postInput_field}
                        required
                      />
                    )}
                  </FormattedMessage>
                </span>
                <button type="submit" className={styles.postInput_icon}>
                  <span className="sr-only">Search</span>
                  <i className="fas fa-search" />
                </button>
              </form>
            </div>
          </Col>
          <Col sm={6} xs={12} className={styles.footerPhone}>
            <h2>
              <FormattedMessage {...messages.footerNeedHelp} />
              {' '}
              <a href={`tel:${phoneNumber}`}>
                <span className={styles.phoneNumber}>
                  <FormattedMessage {...messages.helpPhone} />
                </span>
              </a>
            </h2>
          </Col>
        </Row>
        <p className="mb-4">
          <i className="fa fa-wheelchair" />&nbsp;
          <FormattedMessage {...messages.assistanceStatement} />
        </p>
        <p className="mb-0">
          &copy;{new Date().getFullYear()}{' '}
          <FormattedMessage {...messages.footerCopyRight} />
        </p>
        <p className="mb-4">
          <a className={styles.link} href={privacyPolicyUrl}>
            <FormattedMessage {...messages.footerPrivacyPolicy} />
          </a>
          {props.currentLocale === 'en-us' &&
            <a className={styles.link} href={`${storageMartBaseUrl}/sitemap/storagemart-properties-by-entity`}>
              <FormattedMessage {...messages.footerOwningEntities} />
            </a>
          }
        </p>
        <p style={{ maxWidth: '48rem' }}>
          <FormattedMessage {...messages.footerPromotionsDisclaimer} />
        </p>
      </Container>
    </footer >
  );
}

const mapState = state => ({
  currentLocale: getCurrentLocale(state),
  storageMartBaseUrl: state.runtime.storageMartBaseUrl,
});

export default injectIntl(compose(
  withStyles(styles),
  connect(mapState, null),
)(withApollo(FooterComponent)));
