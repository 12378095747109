import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './styles.scss';

const messages = defineMessages({
  logout: {
    id: 'header.logout',
    defaultMessage: 'Logout',
    description: 'Logout button text in header',
  },
});

export const LogoutButtonComponent = () => (
  <Button
    href="/logout"
    className={`btn btn-secondary d-lg-inline-block ${styles.logout}`}
  >
    <span className={styles.logout_text} > <FormattedMessage {...messages.logout} /></span>
    <i className={`fas fa-fw fa-sign-out-alt ${styles.logout_icon}`} />
  </Button>
);

export default withStyles(styles)(LogoutButtonComponent);
