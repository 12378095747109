import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LanguageSwitcher from '../LanguageSwitcher';
import Navigation from '../Navigation';
import LogoutButton from '../LogoutButton';
import {
  getUser,
  getCurrentLocale,
} from '../../selectors';
import localeHelper from '../../helpers/localeHelper';
import styles from './styles.scss';


const messages = defineMessages({
  helpPhone: {
    id: 'header.helpPhone',
    defaultMessage: '(000) 000-0000',
    description: 'Phone in page header',
  },
  logout: {
    id: 'header.logout',
    defaultMessage: 'Logout',
    description: 'Logout button text in header',
  },
});

export const HeaderComponent = class Header extends React.Component {
    static propTypes = {
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    };
    constructor(props, context) {
      super(props, context);
      this.state = {
        navOpen: false,
        isOpenMenu: false,
        menuButtonIcon: 'bars',
        menuButtonText: 'Menu Closed',
      };
      this.onExpand = this.onExpand.bind(this);
      this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    onExpand = (expanded) => {
      this.setState({ navOpen: expanded });
    }

    handleButtonClick() {
      this.setState(() => ({
        isOpenMenu: !this.state.isOpenMenu,
        menuButtonIcon: this.state.isOpenMenu ? 'bars' : 'times',
        menuButtonText: this.state.isOpenMenu ? 'Menu Closed' : 'Menu Open',
      }));
    }

    render() {
      const { user, intl } = this.props;
      const phoneNumber = localeHelper.getPhoneNumber(intl.formatMessage(messages.helpPhone), intl.locale);
      const isLoggedIn = user && user.id && user.id.length > 0;
      return (
        <div>
          <div className={`${styles.header} ${this.state.isOpenMenu && styles.header_open}`}>
            <a href="https://www.storage-mart.com" className={styles.logo}>
              <img
                src="https://uploads.storage-mart.com/a0756a77-5ab9-4c50-b715-fd5a68030d77/sm-mms-header-logo.svg"
                className={styles.logo_img}
                alt="StorageMart logo"
                loading="eager"
                width="213px"
                height="54px"
              />
            </a>
            <div className={styles.controls} >
              <a href={`tel:${phoneNumber}`}>
                <span className={styles.phoneNumber}>
                  <i
                    className={`fas fa-fw fa-phone fa-flip-horizontal ${styles.phoneNumber_icon}`}
                    aria-hidden="true"
                  /> <FormattedMessage {...messages.helpPhone} />
                </span>
              </a>
              <a
                href={`tel:${phoneNumber}`}
                className={`btn btn-secondary  ${styles.phoneNumberButton}`}
                aria-label={phoneNumber}
              >
                <i
                  className={`fas fa-fw fa-phone d-sm-inline-block fa-flip-horizontal
                  ${styles.phoneNumberButton_icon}`}
                  aria-hidden="true"
                />
              </a>
              <span className={styles.separator} />
              <span className={styles.languageSwitcher}>
                <LanguageSwitcher />
              </span>
              {user ? <LogoutButton />
                :
                ''
              }
              <button
                onClick={this.handleButtonClick}
                className={`btn btn-secondary ${styles.nav_toggler} ${styles.navBarMobile}`}
                aria-expanded={this.state.isOpenMenu}
                aria-label={this.state.menuButtonText}
              >
                <i className={`fa fa-fw fa-${this.state.menuButtonIcon}`} aria-hidden="true" />
              </button>
            </div>
          </div>
          {this.state.isOpenMenu &&
            <div className={styles.navBarMobile} >
              <Navigation onExpand={this.handleButtonClick} />
            </div>
          }
          {isLoggedIn &&
            <div className={styles.navBarDesktop} >
              <Navigation />
            </div>
          }
        </div>
      );
    }
};

const mapState = state => ({
  user: getUser(state),
  currentLocale: getCurrentLocale(state),
});

export default injectIntl(connect(mapState)(withStyles(styles)(HeaderComponent)));
