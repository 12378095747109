exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._12yMj{position:fixed;top:5px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);z-index:1051}._12yMj ._1v08z{position:relative;padding-right:20px}._12yMj ._1rDXW{position:absolute;top:5px;right:5px;cursor:pointer;color:#4a4a4a;font-size:20px}._12yMj ._1v08z .j_mpd{padding-right:40px;margin:20px}", ""]);

// exports
exports.locals = {
	"alerts_container": "_12yMj",
	"alert_box": "_1v08z",
	"alert_box_close": "_1rDXW",
	"alert_item": "j_mpd"
};