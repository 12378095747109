import React from 'react';
import { compose } from 'react-apollo';
import { Button } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import styles from './styles.scss';

export const cookiesPolicyKey = 'StorageMartCookiesPolicySeen';

const messages = defineMessages({
  messageBanner: {
    id: 'message.Banner',
    defaultMessage: `StorageMart uses cookies to enhance your experience.
      We use these for advertising and analytics purposes.
      By continuing to use this site, you agree to our`,
    description: 'Banner message',
  },
  linkCookiePolicy: {
    id: 'link.cookiePolicy',
    defaultMessage: 'cookie policy',
    description: 'Cookie Policy text',
  },
  buttonAcceptCookies: {
    id: 'button.acceptCookies',
    defaultMessage: 'I accept',
    description: 'Button to accept cookies',
  },
});

export const PolicyBannerComponent = class PolicyBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      beenSeen: false,
    };
  }

  acceptCookiesPolicy = () => {
    window.localStorage.setItem(cookiesPolicyKey, true);
    this.setState({
      beenSeen: true,
    });
  }

  render() {
    const { beenSeen } = this.state;

    if (beenSeen) { return null; }
    return (
      <div className={styles.banner}>
        <FormattedMessage {...messages.messageBanner} />
        <a href="https://www.storage-mart.com/en-gb/about-us/privacy-policy/cookies">
          <FormattedMessage {...messages.linkCookiePolicy} />
        </a>
        <Button
          context="bare"
          className={styles.acceptBtn}
          onClick={this.acceptCookiesPolicy}
        >
          <FormattedMessage {...messages.buttonAcceptCookies} />
        </Button>
      </div>
    );
  }
};

export default injectIntl(compose(withStyles(styles))(PolicyBannerComponent));
